<div
  class="container"
  ngClass="desktop"
  ngClass.xs="xs"
  fxLayout="column"
  fxLayoutAlign="center"
  fxLayoutGap="24px"
>
  <!-- Start Title -->
  <h1 class="title">Companies we <br />help created</h1>
  <!-- End Title -->
  <!-- Start Companies -->
  <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="40px grid">
    <div
      fxLayout="column"
      fxLayoutAlign="start start"
      fxFlex="calc(33% - 40px)"
      fxFlex.xs="100%"
      fxFlex.lg="25%"
      fxFlex.xl="20%"
      *ngFor="let company of createdCompanies"
    >
      <div
        class="companyImg"
        [style.backgroundImage]="'url(' + company.imgUrl + ')'"
      ></div>
      <h2>{{ company.name }}</h2>
    </div>
  </div>
  <!-- End Companies -->
</div>
