import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  aboutUsDescription = `At Eurogain, We believe in helping our clients 
  to make their businesses grow. We are passionate about providing 
  professional corporate services in formulating strategy systems and 
  resources so as to have a powerful and positive impact on their 
  businesses.`;

  historyDescription = `Initially we only provided secretarial services, 
  but the organization subsequently expended to provide a variety of 
  related business services. This included bookkeeping, taxation and 
  consulting services. In 1999 our office moved from Jalan Masjid India 
  to Phoenix Business Park.In 2000 we implemented strategic planning methods 
  in our organization. We expanded our human resource to include more 
  professional consultants and commenced Multi Disciplinary Practice 
  concept with diversified consulting businesses. We have created smart 
  partnership with professionals in other specialized business fields to 
  provide better service to our clients.At the moment the staffs is over 
  40 and we have over 5000 square foot occupied. Together with our team of 
  qualified, dedicated and efficient staff, the company’s client’s base has 
  increased in progressive rates every year.`;

  visionDescription = `We aim to provide the best service possible for the 
  benefits of our clients. In short, Eurogain will serve as a stepping stone 
  in helping our clients to grow their businesses and a gateway that opens 
  the path to the booming ASEAN market by leveraging on various smart 
  partnerships. Besides constant innovation, we are committed to ensuring 
  continuous improvement and development of our services and team.`;

  missions = [
    `To generate a powerful and positive impact on our client’s business and 
    quality of life with our professional services that encompass strategies, 
    systems and resources.`,
    `To encourage open and sincere communication and care deeply about our 
    relationships with our team, clients and suppliers.`,
    `To strive for innovation and to be committed to the continuous 
    improvement and development of our team and services.`,
    `To measure our success from the endorsements received from our clients 
    who engage our services.`,
    `To respect and recognise the efforts of our team and provide them 
    with an opportunity to grow and share the success of our organisation.`,
  ];

  constructor(private metaService: Meta, private titleService: Title) {
    this.updateTitle();
    this.updateMetaTags();
  }

  ngOnInit(): void {}

  updateTitle(): void {
    this.titleService.setTitle('Eurogain — About Us');
  }

  updateMetaTags(): void {
    // Start Update Primary Title
    this.metaService.updateTag({
      name: 'title',
      content: 'Eurogain — About Us',
    });

    // Start Update Description
    this.metaService.updateTag({
      name: 'description',
      content: this.aboutUsDescription,
    });

    // Update OpenGraph Title and Description
    this.metaService.updateTag({
      property: 'og:title',
      content: 'Eurogain — About Us',
    });

    this.metaService.updateTag({
      property: 'og:description',
      content: this.aboutUsDescription,
    });

    this.metaService.updateTag({
      property: 'og:url',
      content: `${environment.baseUrl}/about-us`,
    });

    // Update Twitter Meta Tags
    this.metaService.updateTag({
      property: 'twitter:title',
      content: 'Eurogain — About Us',
    });

    this.metaService.updateTag({
      property: 'twitter:description',
      content: this.aboutUsDescription,
    });

    this.metaService.updateTag({
      property: 'twitter:url',
      content: `${environment.baseUrl}/about-us`,
    });
  }
}
