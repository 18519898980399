<form
  [formGroup]="contactUsForm"
  fxLayout="column"
  fxLayoutAlign="stretch"
  #formDirective="ngForm"
  (ngSubmit)="submitContactForm()"
>
  <!-- Start Name -->
  <mat-form-field appearance="outline">
    <mat-label>Your Name</mat-label>
    <input matInput type="text" formControlName="name" required />
    <mat-error *ngIf="contactUsForm.controls['name'].hasError('required')">
      Your Name is required
    </mat-error>
  </mat-form-field>
  <!-- End Name -->
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="stretch"
    fxLayoutGap="16px"
    fxLayoutGap.xs="0"
  >
    <!-- Start Phone Number -->
    <mat-form-field appearance="outline" fxFlex="calc(50% - 14px)">
      <mat-label>Your Phone Number</mat-label>
      <input matInput type="tel" formControlName="phoneNumber" required />
      <mat-error
        *ngIf="contactUsForm.controls['phoneNumber'].hasError('required')"
      >
        Your Phone Number is required
      </mat-error>
    </mat-form-field>
    <!-- End Phone Number -->
    <!-- Start Email Address -->
    <mat-form-field appearance="outline" fxFlex="calc(50% - 14px)">
      <mat-label>Your Email Address</mat-label>
      <input matInput type="email" formControlName="emailAddress" required />
      <mat-error
        *ngIf="contactUsForm.controls['emailAddress'].hasError('required')"
      >
        Your Email Address is required
      </mat-error>
      <mat-error
        *ngIf="contactUsForm.controls['emailAddress'].hasError('email')"
      >
        Please enter a valid email address
      </mat-error>
    </mat-form-field>
    <!-- End Email Address -->
  </div>
  <!-- Start Title -->
  <mat-form-field appearance="outline" *ngIf="isNormal">
    <mat-label>Title</mat-label>
    <input matInput type="text" formControlName="title" required />
    <mat-error *ngIf="contactUsForm.controls['title'].hasError('required')">
      Title is required
    </mat-error>
  </mat-form-field>
  <!-- End Title -->
  <!-- Start Remarks -->
  <mat-form-field appearance="outline">
    <mat-label>Remarks</mat-label>
    <textarea
      matInput
      type="text"
      rows="4"
      formControlName="remarks"
      required
    ></textarea>
    <mat-error *ngIf="contactUsForm.controls['remarks'].hasError('required')">
      Remarks is required
    </mat-error>
  </mat-form-field>
  <!-- End Remarks -->
  <!-- Start Upload Files Button -->
  <div
    fxLayout="column"
    fxLayoutAlign="stretch"
    class="margin-bottom-40"
    *ngIf="isNormal"
  >
    <input
      formControlName="file"
      type="file"
      class="file-input"
      (change)="uploadFilePressed($event)"
      accept="image/*, .pdf, .doc, .docx, .txt, .xls, .xlxs"
      #fileUpload
    />
    <button
      (click)="fileUpload.click()"
      mat-stroked-button
      type="button"
      [color]="pendingFiles.length ? 'normal' : 'primary'"
      [disabled]="isUploading"
    >
      <mat-icon class="material-icons-outlined">{{
        pendingFiles.length ? "image" : "upload"
      }}</mat-icon>
      <span class="margin-left-8">{{
        pendingFiles.length ? pendingFiles[0].file.name : "Upload File"
      }}</span>
    </button>
  </div>
  <!-- End Upload Files Button -->
  <!-- Start Send Button -->
  <button mat-raised-button color="primary" type="submit" class="uppercase">
    Send
  </button>
  <!-- End Send Button -->
</form>
