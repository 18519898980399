import { Meta, Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  description = `Candy cake pastry pudding pie liquorice gingerbread cake. 
  Jelly beans apple pie pie candy sesame snaps tootsie roll chocolate bar. 
  Danish dessert marzipan pudding chupa chups toffee sweet roll.`;

  form_description = `Tootsie roll wafer dessert dragée cookie cake ice cream 
  tootsie roll jelly. Gingerbread cake candy canes muffin fruitcake. Sweet 
  topping caramels gummi bears bear claw.`;

  constructor(private metaService: Meta, private titleService: Title) {
    this.updateTitle();
    this.updateMetaTags();
  }

  ngOnInit(): void {}

  updateTitle(): void {
    this.titleService.setTitle('Eurogain — Contact Us');
  }

  updateMetaTags(): void {
    // Start Update Primary Title
    this.metaService.updateTag({
      name: 'title',
      content: 'Eurogain — Contact Us',
    });

    // Start Update Description
    this.metaService.updateTag({
      name: 'description',
      content: this.description,
    });

    // Update OpenGraph Title and Description
    this.metaService.updateTag({
      property: 'og:title',
      content: 'Eurogain — Contact Us',
    });

    this.metaService.updateTag({
      property: 'og:description',
      content: this.description,
    });

    this.metaService.updateTag({
      property: 'og:url',
      content: `${environment.baseUrl}/contact`,
    });

    // Update Twitter Meta Tags
    this.metaService.updateTag({
      property: 'twitter:title',
      content: 'Eurogain — Contact Us',
    });

    this.metaService.updateTag({
      property: 'twitter:description',
      content: this.description,
    });

    this.metaService.updateTag({
      property: 'twitter:url',
      content: `${environment.baseUrl}/contact`,
    });
  }
}
