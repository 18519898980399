import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpTokenInterceptor } from './core/interceptor/http-token.interceptor';
import { SafePipe } from './core/pipes/safe.pipe';
import { AboutUsComponent } from './modules/about-us/about-us.component';
import { ArticleDetailsComponent } from './modules/articles/article-details/article-details.component';
import { ArticlesComponent } from './modules/articles/articles.component';
import { CategoryArticlesComponent } from './modules/articles/category-articles/category-articles.component';
import { ContactFormComponent } from './modules/contact/contact-form/contact-form.component';
import { ContactIntroComponent } from './modules/contact/contact-intro/contact-intro.component';
import { ContactComponent } from './modules/contact/contact.component';
import { FooterComponent } from './modules/footer/footer.component';
import { HomeCompaniesCreatedComponent } from './modules/home/home-companies-created/home-companies-created.component';
import { HomeIntroComponent } from './modules/home/home-intro/home-intro.component';
import { HomeWhatWeOfferComponent } from './modules/home/home-what-we-offer/home-what-we-offer.component';
import { HomeComponent } from './modules/home/home.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { AuditServiceComponent } from './modules/services/audit-service/audit-service.component';
import { SecretaryServiceComponent } from './modules/services/secretary-service/secretary-service.component';
import { ServiceFaqComponent } from './modules/services/service-faq/service-faq.component';
import { ServiceIntroComponent } from './modules/services/service-intro/service-intro.component';
import { ServicePricingComponent } from './modules/services/service-pricing/service-pricing.component';
import { SharedServicesServiceComponent } from './modules/services/shared-services-service/shared-services-service.component';
import { TaxServiceComponent } from './modules/services/tax-service/tax-service.component';
import { ToolbarComponent } from './modules/toolbar/toolbar.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ArticlesComponent,
    ToolbarComponent,
    HomeIntroComponent,
    HomeWhatWeOfferComponent,
    HomeCompaniesCreatedComponent,
    FooterComponent,
    ContactComponent,
    ContactIntroComponent,
    ContactFormComponent,
    SecretaryServiceComponent,
    ServiceIntroComponent,
    ServicePricingComponent,
    ServiceFaqComponent,
    TaxServiceComponent,
    AuditServiceComponent,
    SharedServicesServiceComponent,
    AboutUsComponent,
    NotFoundComponent,
    CategoryArticlesComponent,
    ArticleDetailsComponent,
    SafePipe,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    HttpClientModule,
    AppRoutingModule,
    FlexLayoutModule,
    // Material Design
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatExpansionModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-NHS835W',
    }),
  ],
  providers: [
    Meta,
    DatePipe,
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-NHS835W',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
