import { environment } from './../../../environments/environment.staging';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private metaService: Meta, private titleService: Title) {
    this.updateTitle();
    this.updateMetaTags();
  }

  ngOnInit(): void {}

  updateTitle(): void {
    this.titleService.setTitle('Eurogain');
  }

  updateMetaTags(): void {
    // Update Primary Meta Tags
    this.metaService.updateTag({
      name: 'title',
      content: 'Eurogain',
    });

    this.metaService.updateTag({
      name: 'description',
      content: `At Eurogain, we believe in helping our clients to make their businesses grow. 
      We are passionate about providing professional corporate services in formulating strategy 
      systems and resources so as to have a powerful and positive impact on their businesses.`,
    });

    // Update OpenGraph Meta Tags
    this.metaService.updateTag({
      property: 'og:title',
      content: 'Eurogain',
    });

    this.metaService.updateTag({
      property: 'og:description',
      content: `At Eurogain, we believe in helping our clients to make their businesses grow. 
      We are passionate about providing professional corporate services in formulating strategy 
      systems and resources so as to have a powerful and positive impact on their businesses.`,
    });

    // Update Twitter Meta Tags
    this.metaService.updateTag({
      property: 'twitter:title',
      content: 'Eurogain',
    });

    this.metaService.updateTag({
      property: 'twitter:description',
      content: `At Eurogain, we believe in helping our clients to make their businesses grow. 
      We are passionate about providing professional corporate services in formulating strategy 
      systems and resources so as to have a powerful and positive impact on their businesses.`,
    });
  }
}
