<div
  fxLayout="column"
  fxLayoutAlign="stretch"
  class="container"
  ngClass="desktop"
  ngClass.xs="xs"
>
  <!-- Start Title -->
  <h1 class="title text-align-center">Get a plan that works for you</h1>
  <!-- End Title -->
  <!-- Start Description -->
  <p *ngIf="description" class="text-align-center">{{ description }}</p>
  <!-- End Description -->
  <div
    fxLayout="row wrap"
    fxLayoutAlign="center start"
    fxLayoutGap="16px grid"
    class="margin-top-40"
    ngClass="padding-40"
    ngClass.xs="padding-24"
  >
    <!-- Start Pricing Item -->
    <div
      fxFlex.xs="100%"
      fxFlex="33%"
      fxFlex.lg="25%"
      fxFlex.xl="20%"
      *ngFor="let servicePrice of servicePrices"
    >
      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="32px"
        class="border border-color border-radius-8 padding-16"
      >
        <!-- Start Service Title -->
        <div>
          <h1 class="text-align-center no-margin">{{ servicePrice.name }}</h1>
        </div>
        <!-- End Service Title -->
        <!-- Start Price -->
        <div fxLayout="column" fxLayoutAlign="start center">
          <div
            class="
              border border-color border-radius-8
              padding-8
              margin-bottom-16
            "
            fxLayout="row"
            fxLayoutAlign="center"
          >
            <p class="no-margin">RM{{ servicePrice.pricePerMonth }} / month</p>
          </div>
          <small>or prepay annually:</small>
          <small>RM{{ servicePrice.pricePerAnnual }} / year</small>
        </div>
        <!-- End Price -->
        <mat-divider></mat-divider>
        <!-- Start Service Breakdown -->
        <div>
          <p>{{ servicePrice.name }} includes</p>
          <!-- Start List of Breakdown -->
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="16px"
            *ngFor="let item of servicePrice.itemIncludes"
          >
            <mat-icon>check</mat-icon>
            <p class="no-margin">{{ item }}</p>
          </div>
          <!-- End List of Breakdown -->
        </div>
        <!-- End Service Breakdown -->
      </div>
    </div>
    <!-- End Pricing Item -->
  </div>
</div>
