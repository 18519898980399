import { QuestionAnswer } from './../../../shared/models/question-answer/question-answer.model';
import { FAQConstants } from './../../../shared/constants/faq.constants';
import { Component, OnInit } from '@angular/core';
import { ServicesPricesConstants } from 'src/app/shared/constants/services-prices.constants';
import { ServicePrice } from 'src/app/shared/models/service-price/service-price.model';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-secretary-service',
  templateUrl: './secretary-service.component.html',
  styleUrls: ['./secretary-service.component.scss'],
})
export class SecretaryServiceComponent implements OnInit {
  description = `
  We serve an exclusive range of coffees and offer a wide assortment of teas 
  to suit every occasion. We believe that coffee is our passion, our passion 
  for quality, our passion for people and our passion for the great coffee 
  experience – and we always will. It's our mission to help people find the 
  joy of coffee, to help people stop worrying, to help people get up and leave 
  their comfort zone and to help people enjoy a cup of tea without the worries, 
  without the stress.
  `;
  form_description = `Fill up the form below and we will reach out to you`;
  imageUrl = 'assets/images/secretary_intro.svg';

  // Pricing List
  secretaryServicePrices: ServicePrice[] = [];

  // FAQs
  questionsAnswers: QuestionAnswer[] = [];

  constructor(
    private servicesPricesConstants: ServicesPricesConstants,
    private faqConstants: FAQConstants,
    private metaService: Meta,
    private titleService: Title
  ) {
    this.secretaryServicePrices = this.servicesPricesConstants.SECRETARY_PRICES;
    this.questionsAnswers = this.faqConstants.SECRETARY_QUESTIONS_ANSWERS;
    this.updateTitle();
    this.updateMetaTags();
  }

  ngOnInit(): void {}

  updateTitle(): void {
    this.titleService.setTitle('Eurogain — Secretary');
  }

  updateMetaTags(): void {
    // Start Update Primary Title
    this.metaService.updateTag({
      name: 'title',
      content: 'Eurogain — Secretary',
    });

    // Start Update Description
    this.metaService.updateTag({
      name: 'description',
      content: this.description,
    });

    // Update OpenGraph Title and Description
    this.metaService.updateTag({
      property: 'og:title',
      content: 'Eurogain — Secretary',
    });

    this.metaService.updateTag({
      property: 'og:description',
      content: this.description,
    });

    this.metaService.updateTag({
      property: 'og:url',
      content: `${environment.baseUrl}/services/secretary`,
    });

    // Update Twitter Meta Tags
    this.metaService.updateTag({
      property: 'twitter:title',
      content: 'Eurogain — Secretary',
    });

    this.metaService.updateTag({
      property: 'twitter:description',
      content: this.description,
    });

    this.metaService.updateTag({
      property: 'twitter:url',
      content: `${environment.baseUrl}/services/secretary`,
    });
  }
}
