<div
  fxLayout="column"
  fxLayoutAlign="stretch"
  class="container"
  ngClass.xs="xs"
>
  <!-- Start Title -->
  <h1 class="title text-align-center">Frequently Asked Questions</h1>
  <!-- End Title -->
  <!-- Start Questions -->
  <mat-accordion>
    <mat-expansion-panel
      class="mat-elevation-z0 expansion-panel"
      *ngFor="
        let questionAnswer of questionsAnswers;
        let isFirst = first;
        let isLast = last
      "
      [class.first]="isFirst"
      [class.last]="isLast"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="panel-title">
          {{ questionAnswer.question }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="padding-16">
        <p>{{ questionAnswer.answer }}</p>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- End Questions -->
</div>
