<div
  class="intro-container"
  ngClass="desktop"
  ngClass.sm="sm"
  ngClass.md="md"
  ngClass.xs="xs"
  ngClass.lg="lg"
  ngClass.xl="xl"
  ngClass.gt-md="gt-md"
>
  <!-- Start About Us -->
  <div
    fxLayout="column"
    fxLayoutAlign="center start"
    class="about_us_container"
  >
    <!-- Start Title -->
    <h1 class="title">About us</h1>
    <!-- End Title -->
    <!-- Start Description -->
    <p>{{ aboutUsDescription }}</p>
    <!-- End Description -->
  </div>
  <!-- End About Us -->
</div>

<div
  fxLayout="column"
  fxLayoutAlign="stretch"
  fxLayoutGap="56px"
  ngClass.xs="padding-24 margin-24"
  ngClass="padding-40 margin-40"
>
  <!-- Start Vision -->
  <div>
    <h1>Vision</h1>
    <p>{{ visionDescription }}</p>
  </div>
  <!-- End Vision -->

  <!-- Start Mission -->
  <div>
    <h1>Mission</h1>
    <ul>
      <li *ngFor="let mission of missions">{{ mission }}</li>
    </ul>
  </div>
  <!-- End Mission -->

  <!-- Start History -->
  <div>
    <h1>History</h1>
    <p>
      Eurogain was established in 1996 by Felicia Lee and subsequently with
      Kevin Kiew’s support, they formed :
    </p>
    <ul>
      <li>EUROGAIN CONSULTING GROUP SDN. BHD. – 200301035836 (638257-V)</li>
      <li>EUROGAIN SECTA SDN. BHD. – 201101034021 (962156-X)</li>
      <li>EUROGAIN SECRETARIAL SERVICES SDN. BHD. – 200501007339 (684386-H)</li>
      <li>EUROGAIN MANAGEMENT SERVICES SDN. BHD. – 199901013072 (487972-D)</li>
    </ul>
    <p>{{ historyDescription }}</p>
  </div>
  <!-- End History -->
</div>
