import { JwtService } from './../services/jwt/jwt.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const isFormDataContentType: boolean =
      this.jwtService.contentTypeIsFormData();

    const jsonRequest = request.clone({
      headers: request.headers
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json'),
      // .set('Authorization', `Bearer ${token}`),
    });

    const formDataRequest = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    return next.handle(isFormDataContentType ? formDataRequest : jsonRequest);
  }
}
