<footer
  fxLayout="row wrap"
  fxLayoutGap.xs="56px grid"
  fxLayoutGap="56px grid"
  ngClass.xs="xs"
  class="width-100"
  ngClass="desktop"
>
  <!-- Start Services Section -->
  <div
    fxLayout="column"
    fxLayoutAlign="start center"
    fxFlex="25%"
    fxFlex.xs="100%"
    fxFlex.sm="50%"
    fxFlex.md="50%"
    fxFlex.lg="33%"
    fxFlex.xl="20%"
    fxLayoutGap="16px"
  >
    <a mat-button href="https://eurogain.com.my/">
      <img
        src="assets/images/eurogain-logo.png"
        alt=""
        class="logo"
        ngClass.xs="xs"
      />
    </a>
  </div>
  <!-- End Services Section -->
  <!-- Start Information Section -->
  <div
    fxLayout="column"
    fxLayoutAlign="start start"
    fxFlex="25%"
    fxFlex.xs="100%"
    fxFlex.sm="50%"
    fxFlex.md="50%"
    fxFlex.lg="33%"
    fxFlex.xl="20%"
    fxLayoutGap="16px"
  >
    <p class="uppercase bold section-title">About Us</p>
    <div fxLayout="column" fxLayoutGap="8px" fxFlex>
      <p>
        At Eurogain, we believe in helping our clients to make their businesses
        grow. We are passionate about providing professional corporate services
        in formulating strategy systems and resources so as to have a powerful
        and positive impact on their businesses.
      </p>
    </div>
    <div
      fxLayout="row"
      fxLayoutGap="8px"
      fxLayoutAlign="start center"
      class="padding-bottom-16"
    >
      <a
        mat-icon-button
        href="https://www.facebook.com/eurogain"
        target="_blank"
      >
        <img src="assets/images/facebook.svg" alt="facebook" />
      </a>
      <a mat-icon-button href="mailto:info@eurogain.com.my">
        <img src="assets/images/email.svg" alt="email" />
      </a>
      <!-- <a mat-icon-button href="" target="_blank">
        <img src="assets/images/instagram.svg" alt="instagram" />
      </a>
      <a mat-icon-button href="" target="_blank">
        <img src="assets/images/twitter.svg" alt="twitter" />
      </a> -->
    </div>
  </div>
  <!-- End Information Section -->
  <!-- Start Contact Section -->
  <div
    fxLayout="column"
    fxLayoutAlign="start start"
    fxFlex="25%"
    fxFlex.xs="100%"
    fxFlex.sm="50%"
    fxFlex.md="50%"
    fxFlex.lg="33%"
    fxFlex.xl="20%"
    fxLayoutGap="16px"
  >
    <p class="uppercase bold section-title">Contact Us</p>
    <div fxLayout="column" fxLayoutGap="8px">
      <p>Phone:</p>
      <a href="tel:+603-9058 1020">+603-9058 1020</a>
      <a href="tel:+603-9058 1050">+603-9058 1050</a>
      <a href="tel:+603-9058 1080">+603-9058 1080</a>
      <a href="tel:+603-9058 1060">+603-9058 1060</a>
      <p class="margin-top-16">Email:</p>
      <a href="mailto:info@eurogain.com.my" class="ellipsis"
        >info@eurogain.com.my</a
      >
    </div>
  </div>
  <!-- End Contact Section -->
  <!-- Start Copyright Section -->
  <div
    fxLayout="column"
    fxLayoutAlign="space-between end"
    fxLayoutAlign.xs="center"
    fxFlex="25%"
    fxFlex.xs="100%"
    fxFlex.sm="calc(50%)"
    fxFlex.md="calc(33%)"
    fxFlex.lg="calc(25%)"
    fxFlex.xl="calc(20%)"
  >
    <div fxLayout="column" fxLayoutAlign="end end" fxLayoutAlign.xs="center">
      <small ngClass="text-align-right" ngClass.xs="text-align-center"
        >Eurogain Consulting Group Sdn Bhd</small
      >
      <small ngClass="text-align-right" ngClass.xs="text-align-center"
        >200301035836 (638257-V) © 2020 All Rights Reserved</small
      >
    </div>
  </div>
  <!-- End Copyright Section -->
</footer>
