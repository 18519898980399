<div
  fxLayout="row"
  fxLayoutAlign="center"
  fxLayout.xs="column"
  fxLayoutAlign.xs="start start"
>
  <div
    fxLayout="column"
    fxLayoutAlign="start start"
    class="container"
    ngClass.xs="xs"
    ngClass.sm="sm"
    ngClass.md="md"
    ngClass.lg="lg"
    ngClass.xl="xl"
  >
    <!-- Start Published Date -->
    <p>
      {{ article ? (article.publishedDate | date) : "Loading" }}
    </p>
    <!-- End Published Date -->

    <!-- Start Title & Short Description -->
    <p class="article-title" ngClass.xs="xs">
      {{ article ? article.mainTitle : "Loading" }}
    </p>
    <p class="article-shortDescription" ngClass.xs="xs">
      {{ article ? article.shortDescription : "Loading" }}
    </p>
    <!-- End Title & Short Description -->

    <!-- Start Author -->
    <div
      fxLayout="column"
      fxLayoutAlign="start start"
      class="margin-top-48 margin-bottom-48"
    >
      <p class="article-author">
        <span class="bold">{{
          article ? article.author.name : "Loading"
        }}</span>
        <span class="capitalize author-role"
          >,
          {{
            article ? article.author.roles[0].role.toLowerCase() : "Loading"
          }}</span
        >
      </p>
    </div>
    <!-- End Author -->

    <!-- Start Article Image -->
    <img
      [src]="getImageUrl()"
      *ngIf="!isLoading && article.fileUrl"
      class="article-image"
    />
    <!-- End Article Image -->

    <!-- Start Content -->
    <div
      [innerHtml]="article.fullDescription | safe: 'html'"
      class="margin-vertical-56 article-content"
      *ngIf="!isLoading && article"
    ></div>
    <!-- End Content -->

    <!-- Start Tags -->
    <p class="subtitle" *ngIf="!isLoading && article">
      Tags:
      <span
        class="capitalize"
        *ngFor="
          let department of getCategoriesStr(article.categories);
          let isLast = last
        "
      >
        {{ department.toLowerCase() }}{{ isLast ? "" : ", " }}
      </span>
    </p>
    <!-- End Tags -->

    <!-- Start Reference Link -->
    <div fxLayout="row" *ngIf="!isLoading && article">
      <p>Reference:</p>
      <a [href]="article.referenceLink" class="margin-left-8">
        {{ article.referenceLink }}
      </a>
    </div>
    <!-- End Reference Link -->

    <!-- Start Article Engagement -->
    <div
      *ngIf="!isLoading && article"
      fxLayout.xs="column"
      fxLayoutAlign.xs="stretch"
      fxLayoutGap.xs="32px"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="16px"
      class="
        border border-color border-radius-8
        padding-24
        width-100
        margin-vertical-56
      "
    >
      <div fxFlex="calc(70% - 16px)">
        <p class="bold">{{ article.engagementTitle }}</p>
        <p class="no-margin">{{ article.engagementDescription }}</p>
      </div>
      <a mat-stroked-button href="" color="primary" fxFlex>
        {{ article.engagementButtonLabel }}
      </a>
    </div>
    <!-- End Article Engagement -->
  </div>
</div>
