import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ArticlesService } from 'src/app/core/services/articles/articles.service';
import { ArticleCategory } from 'src/app/shared/models/article-category/article-category.model';
import * as _ from 'lodash';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss'],
})
export class ArticlesComponent implements OnInit {
  description = `Candy cake pastry pudding pie liquorice gingerbread cake. 
  Jelly beans apple pie pie candy sesame snaps tootsie roll chocolate bar. 
  Danish dessert marzipan pudding chupa chups toffee sweet roll.`;

  // Booleans
  isLoading = false;

  // Articles
  categoryOptions: BehaviorSubject<Array<ArticleCategory>> =
    new BehaviorSubject<Array<ArticleCategory>>([]);

  constructor(
    private snackBar: MatSnackBar,
    private articlesService: ArticlesService,
    private metaService: Meta,
    private titleService: Title
  ) {
    this.updateTitle();
    this.updateMetaTags();
  }

  ngOnInit(): void {
    this.getAllCategories();
  }

  updateTitle(): void {
    this.titleService.setTitle('Eurogain — Articles');
  }

  updateMetaTags(): void {
    // Start Update Primary Title
    this.metaService.updateTag({
      name: 'title',
      content: 'Eurogain — Articles',
    });

    // Start Update Description
    this.metaService.updateTag({
      name: 'description',
      content: this.description,
    });

    // Update OpenGraph Title and Description
    this.metaService.updateTag({
      property: 'og:title',
      content: 'Eurogain — Articles',
    });

    this.metaService.updateTag({
      property: 'og:description',
      content: this.description,
    });

    this.metaService.updateTag({
      property: 'og:url',
      content: `${environment.baseUrl}/articles`,
    });

    // Update Twitter Meta Tags
    this.metaService.updateTag({
      property: 'twitter:title',
      content: 'Eurogain — Articles',
    });

    this.metaService.updateTag({
      property: 'twitter:description',
      content: this.description,
    });

    this.metaService.updateTag({
      property: 'twitter:url',
      content: `${environment.baseUrl}/articles`,
    });
  }

  getAllCategories(): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', {
      duration: 3000,
    });

    this.articlesService.getAllArticleCategories().subscribe({
      next: (articleCategories: Array<ArticleCategory>) => {
        articleCategories.sort((a, b) => a.name.localeCompare(b.name));
        this.categoryOptions.next(articleCategories);
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }
}
