<div
  class="intro-container"
  ngClass="desktop"
  ngClass.sm="sm"
  ngClass.md="md"
  ngClass.xs="xs"
  ngClass.lg="lg"
  ngClass.xl="xl"
  ngClass.gt-md="gt-md"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <!-- Start Title and Description -->
  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px" fxFlex>
    <h1 class="title">Contact Us</h1>
    <p>
      {{ description }}
    </p>
    <a mat-stroked-button href="tel:+603-9058 1020">+603-9058 1020</a>
    <a mat-stroked-button href="mailto:info@eurogain.com.my"
      >info@eurogain.com.my</a
    >
  </div>
  <!-- End Title and Description -->
  <!-- Start Image -->
  <div fxLayout="column" fxLayoutAlign="center center" fxFlex fxHide.xs>
    <img class="image" src="assets/images/contact-intro.svg" alt="" />
  </div>
  <!-- End Image -->
</div>
