import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-intro',
  templateUrl: './contact-intro.component.html',
  styleUrls: ['./contact-intro.component.scss'],
})
export class ContactIntroComponent implements OnInit {
  @Input() description = '';

  constructor() {}

  ngOnInit(): void {}
}
