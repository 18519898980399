import { ServicePrice } from './../../../shared/models/service-price/service-price.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-pricing',
  templateUrl: './service-pricing.component.html',
  styleUrls: ['./service-pricing.component.scss'],
})
export class ServicePricingComponent implements OnInit {
  @Input()
  description = `Halvah topping sesame snaps liquorice cupcake topping. 
  Sweet roll biscuit gummies carrot cake brownie tart jelly beans 
  cheesecake powder. Cake chocolate pie powder jujubes jelly-o sugar plum. 
  Gummi bears sesame snaps gummies biscuit chocolate chocolate cake jelly 
  danish soufflé. Tart macaroon lemon drops jelly-o marshmallow pie. Candy 
  oat cake lemon drops cake jelly beans sweet roll cookie sesame snaps 
  powder. Cake carrot cake pastry sesame snaps icing candy canes jujubes.`;
  @Input() servicePrices!: ServicePrice[];

  constructor() {}

  ngOnInit(): void {}
}
