import { QuestionAnswer } from './../models/question-answer/question-answer.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FAQConstants {
  public SECRETARY_QUESTIONS_ANSWERS = [
    {
      question: 'What is a secretary service?',
      answer: `A company secretary is an officer of a company (well, not 
        the one you treat as your PA in the office). Under Malaysian law, 
        every company must appoint a company secretary – it is compulsory, 
        not an option. A company secretary has a huge responsibility to ensure 
        the company’s paperwork is in order, filed on time and advise the 
        board to always stay in compliance with statutory and regulatory 
        requirements.`,
    } as QuestionAnswer,
    {
      question: `Can I appoint another company secretary if I am not 
      too happy with my current one?`,
      answer: `Yes, of course. There should not be any minimum lock-in 
      period because the law allows the board to appoint a new company 
      secretary at any time. MISHU was created to provide transparent, 
      efficient and innovative company secretary services. Switch to us 
      if you like what we do here!`,
    } as QuestionAnswer,
  ];

  public TAX_QUESTIONS_ANSWERS = [
    {
      question: 'What is a tax service?',
      answer: `Chocolate bar lemon drops halvah ice cream halvah. 
      Cotton candy soufflé lemon drops toffee gingerbread tootsie roll 
      pastry. Cupcake tootsie roll cake sesame snaps macaroon gingerbread 
      soufflé carrot cake. Icing topping carrot cake biscuit jelly-o wafer. 
      Pudding danish chocolate bar cheesecake sweet roll cookie bonbon bonbon. 
      Sweet roll sugar plum lollipop tart cupcake candy fruitcake pastry 
      liquorice. Ice cream marshmallow croissant dragée cotton candy donut. 
      Carrot cake powder cotton candy cookie caramels danish donut icing. 
      Shortbread pudding croissant cake caramels topping tiramisu jelly. 
      Bear claw wafer croissant jelly pie cake cotton candy apple pie 
      dragée.`,
    } as QuestionAnswer,
    {
      question: `Can I appoint another company tax secretary if I am not 
      too happy with my current one?`,
      answer: `Topping muffin soufflé pudding candy canes biscuit croissant. 
      Sweet roll gummies tart tootsie roll gingerbread jujubes cotton candy 
      chocolate. Apple pie bonbon dessert chocolate marshmallow cookie gummies. 
      Dragée topping pudding tootsie roll sweet roll cheesecake. Cotton candy 
      gummi bears icing caramels gingerbread oat cake. Bear claw cookie icing 
      lollipop marzipan sesame snaps macaroon ice cream. Candy canes muffin 
      soufflé tootsie roll cheesecake ice cream topping cupcake brownie.`,
    } as QuestionAnswer,
  ];

  public AUDIT_QUESTIONS_ANSWERS = [
    {
      question: 'What is a audit service?',
      answer: `An audit is the examination of the financial report of an 
      organisation - as presented in the annual report - by someone independent 
      of that organisation. The financial report includes a balance sheet, an 
      income statement, a statement of changes in equity, a cash flow statement, 
      and notes comprising a summary of significant accounting policies and other 
      explanatory notes.`,
    } as QuestionAnswer,
    {
      question: `Can I appoint another company audit secretary if I am not 
      too happy with my current one?`,
      answer: `Topping muffin soufflé pudding candy canes biscuit croissant. 
      Sweet roll gummies tart tootsie roll gingerbread jujubes cotton candy 
      chocolate. Apple pie bonbon dessert chocolate marshmallow cookie gummies. 
      Dragée topping pudding tootsie roll sweet roll cheesecake. Cotton candy 
      gummi bears icing caramels gingerbread oat cake. Bear claw cookie icing 
      lollipop marzipan sesame snaps macaroon ice cream. Candy canes muffin 
      soufflé tootsie roll cheesecake ice cream topping cupcake brownie.`,
    } as QuestionAnswer,
  ];

  public SHARED_SERVICES_QUESTIONS_ANSWERS = [
    {
      question: 'What is a shared services service?',
      answer: `Caramels pie candy canes tiramisu jelly gingerbread wafer. 
      Wafer apple pie candy canes caramels topping pudding sweet roll 
      fruitcake. Sesame snaps powder marshmallow sweet danish tiramisu 
      fruitcake oat cake muffin. Cake caramels brownie pastry wafer macaroon 
      candy canes. Soufflé cotton candy pie icing oat cake bear claw apple 
      pie toffee tootsie roll. Marshmallow lollipop oat cake jelly-o danish 
      apple pie topping chocolate. Croissant pastry cheesecake gingerbread 
      macaroon. Wafer carrot cake pastry muffin sugar plum. Pie topping jelly 
      beans gummies ice cream bear claw chocolate chupa chups. Bear claw 
      toffee chocolate bar gingerbread lollipop jelly-o.`,
    } as QuestionAnswer,
    {
      question: `Can I appoint another company audit secretary if I am not 
      too happy with my current one?`,
      answer: `Topping muffin soufflé pudding candy canes biscuit croissant. 
      Sweet roll gummies tart tootsie roll gingerbread jujubes cotton candy 
      chocolate. Apple pie bonbon dessert chocolate marshmallow cookie gummies. 
      Dragée topping pudding tootsie roll sweet roll cheesecake. Cotton candy 
      gummi bears icing caramels gingerbread oat cake. Bear claw cookie icing 
      lollipop marzipan sesame snaps macaroon ice cream. Candy canes muffin 
      soufflé tootsie roll cheesecake ice cream topping cupcake brownie.`,
    } as QuestionAnswer,
  ];
}
