import { WhatWeOffer } from '../models/what-we-offer/what-we-offer.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WhatWeOfferConstants {
  public WHAT_WE_OFFER_TYPES = [
    {
      title: 'Secretary',
      description:
        'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.',
    } as WhatWeOffer,
    {
      title: 'Tax',
      description:
        'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.',
    } as WhatWeOffer,
    // {
    //   title: 'Audit',
    //   description:
    //     'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.',
    // } as WhatWeOffer,
    {
      title: 'Shared Services',
      description:
        'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.',
    } as WhatWeOffer,
  ];
}
