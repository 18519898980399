import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  private formatErrors(error: any): Observable<any> {
    let errorMessage = '';

    switch (error.error.message) {
      case 'Bad credentials':
        errorMessage = 'Incorrect email or password.';
        break;
      case 'Error: Email is already in use!':
        errorMessage = 'Looks like the email is already in use';
        break;
      case 'Access is denied':
        errorMessage = 'Access expired. Please login again';
        break;
      case 'Error: Client is already in the system!':
        errorMessage = 'Looks like this client exist';
        break;
      default:
        errorMessage = 'Something went wrong. Try again later.';
        console.log(error);
        break;
    }
    return throwError(errorMessage);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(`${environment.baseUrl}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http
      .put(`${environment.baseUrl}${path}`, JSON.stringify(body))
      .pipe(catchError(this.formatErrors));
  }

  post(path: string, body: object = {}, isFormData?: boolean): Observable<any> {
    return this.http
      .post(
        `${environment.baseUrl}${path}`,
        isFormData ? body : JSON.stringify(body)
      )
      .pipe(catchError(this.formatErrors));
  }

  delete(path: any): Observable<any> {
    return this.http
      .delete(`${environment.baseUrl}${path}`)
      .pipe(catchError(this.formatErrors));
  }
}
