import { ArticleDetailsComponent } from './modules/articles/article-details/article-details.component';
import { AboutUsComponent } from './modules/about-us/about-us.component';
import { SharedServicesServiceComponent } from './modules/services/shared-services-service/shared-services-service.component';
import { TaxServiceComponent } from './modules/services/tax-service/tax-service.component';
import { SecretaryServiceComponent } from './modules/services/secretary-service/secretary-service.component';
import { ContactComponent } from './modules/contact/contact.component';
import { ToolbarComponent } from './modules/toolbar/toolbar.component';
import { HomeComponent } from './modules/home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuditServiceComponent } from './modules/services/audit-service/audit-service.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { ArticlesComponent } from './modules/articles/articles.component';

const routes: Routes = [
  {
    path: '',
    component: ToolbarComponent,
    children: [
      {
        path: '',
        redirectTo: '/articles',
        pathMatch: 'full',
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
      },
      {
        path: 'articles',
        children: [
          {
            path: '',
            component: ArticlesComponent,
          },
          {
            path: ':id',
            component: ArticleDetailsComponent,
          },
        ],
      },
      {
        path: 'services',
        children: [
          {
            path: 'secretary',
            component: SecretaryServiceComponent,
          },
          {
            path: 'tax',
            component: TaxServiceComponent,
          },
          {
            path: 'audit',
            component: AuditServiceComponent,
          },
          {
            path: 'shared_services',
            component: SharedServicesServiceComponent,
          },
        ],
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      { path: '**', redirectTo: '/404' },
      { path: '404', component: NotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
