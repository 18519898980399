<app-service-intro
  title="Audit"
  [description]="description"
  [imageUrl]="imageUrl"
></app-service-intro>
<app-service-pricing [servicePrices]="auditServicePrices"></app-service-pricing>

<app-service-faq [questionsAnswers]="questionsAnswers"></app-service-faq>
<div
  fxLayout="column"
  fxLayoutAlign="stretch"
  class="form-container"
  ngClass="desktop"
  ngClass.xs="xs"
>
  <h1 class="title text-align-center">Ready to engage with us?</h1>
  <p class="text-align-center">{{ form_description }}</p>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutAlign.xs="start start"
    fxLayoutGap="40px"
    fxLayoutGap.xs="0px"
    class="margin-top-16"
  >
    <app-contact-form
      ngClass="width-50"
      ngClass.xs="width-100"
      [isAudit]="true"
    ></app-contact-form>
  </div>
</div>
