import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ArticlesService } from 'src/app/core/services/articles/articles.service';
import { Article } from 'src/app/shared/models/article/article.model';
import { ArticleCategory } from 'src/app/shared/models/article-category/article-category.model';
import { environment } from 'src/environments/environment';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-article-details',
  templateUrl: './article-details.component.html',
  styleUrls: ['./article-details.component.scss'],
})
export class ArticleDetailsComponent implements OnInit {
  // Booleans
  isLoading = false;

  // Variable
  article!: Article;

  constructor(
    private activatedRoute: ActivatedRoute,
    private articlesService: ArticlesService,
    private snackBar: MatSnackBar,
    private metaService: Meta,
    private titleService: Title
  ) {
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        const id = params.id;
        this.getArticle(id);
      },
    });
  }

  ngOnInit(): void {}

  updateTitle(): void {
    this.titleService.setTitle(`Eurogain — ${this.article.mainTitle}`);
  }

  updateMetaTags(): void {
    // Start Update Primary Title
    this.metaService.updateTag({
      name: 'title',
      content: `Eurogain — ${this.article.mainTitle}`,
    });

    // Start Update Description
    this.metaService.updateTag({
      name: 'description',
      content: this.article.shortDescription,
    });

    // Update OpenGraph Title and Description
    this.metaService.updateTag({
      property: 'og:title',
      content: `Eurogain — ${this.article.mainTitle}`,
    });

    this.metaService.updateTag({
      property: 'og:description',
      content: this.article.shortDescription,
    });

    this.metaService.updateTag({
      property: 'og:image',
      content: this.getImageUrl(),
    });

    this.metaService.updateTag({
      property: 'og:url',
      content: `${environment.baseUrl}/articles/${this.article.id}`,
    });

    // Update Twitter Meta Tags
    this.metaService.updateTag({
      property: 'twitter:title',
      content: `Eurogain — ${this.article.mainTitle}`,
    });

    this.metaService.updateTag({
      property: 'twitter:description',
      content: this.article.shortDescription,
    });

    this.metaService.updateTag({
      property: 'twitter:image',
      content: this.getImageUrl(),
    });

    this.metaService.updateTag({
      property: 'twitter:url',
      content: `${environment.baseUrl}/articles/${this.article.id}`,
    });
  }

  getArticle(id: number): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', { duration: 3000 });
    this.articlesService.getArticleDetails(id).subscribe({
      next: (article: Article) => {
        this.isLoading = false;
        this.article = article;
        this.snackBar.dismiss();
        this.updateTitle();
        this.updateMetaTags();
      },
      error: (error) => {
        console.log(error);
        this.snackBar.open(error, '', { duration: 3000 });
        this.isLoading = false;
      },
    });
  }

  getCategoriesStr(articleCategories: Array<ArticleCategory>): string[] {
    const categoriesStr: Array<string> = [];
    for (const articleCategory of articleCategories) {
      categoriesStr.push(articleCategory.name.toLowerCase());
    }
    return categoriesStr;
  }

  getImageUrl(): string {
    return `${environment.baseUrl}/api/core/articles/downloadFile/${this.article.id}`;
  }
}
