import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ArticlesService } from 'src/app/core/services/articles/articles.service';
import { ArticleCategory } from 'src/app/shared/models/article-category/article-category.model';
import { Article } from 'src/app/shared/models/article/article.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-category-articles',
  templateUrl: './category-articles.component.html',
  styleUrls: ['./category-articles.component.scss'],
})
export class CategoryArticlesComponent implements OnInit {
  @Input() category: string = '';

  // Booleans
  isLoading = false;

  // Pagination
  pageSize = 999;
  currentPage = 0;

  // Articles
  articles: Array<Article> = [];

  constructor(
    private articlesService: ArticlesService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getArticles();
  }

  getArticles(): void {
    this.isLoading = true;
    const formData = {
      category: this.category,
      page: this.currentPage,
      size: this.pageSize,
      isPublish: true,
    };

    this.articlesService.getArticles(formData).subscribe({
      next: (data) => {
        if (data !== null) {
          this.articles = data.articles as Article[];
        } else {
          this.articles = [];
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
      },
    });
  }

  getImageUrl(article: Article): string {
    return `${environment.baseUrl}/api/core/articles/downloadFile/${article.id}`;
  }
}
