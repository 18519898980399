import { CompanyCreatedConstants } from '../../../shared/constants/company-created.constants';
import { CompanyCreated } from './../../../shared/models/company-created/company-created.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-companies-created',
  templateUrl: './home-companies-created.component.html',
  styleUrls: ['./home-companies-created.component.scss'],
})
export class HomeCompaniesCreatedComponent implements OnInit {
  createdCompanies: CompanyCreated[];

  constructor(companyCreatedConstants: CompanyCreatedConstants) {
    this.createdCompanies = companyCreatedConstants.CREATED_COMPANIES;
  }

  ngOnInit(): void {}
}
