import { ServicePrice } from '../models/service-price/service-price.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ServicesPricesConstants {
  public SECRETARY_PRICES = [
    {
      name: 'Secretary',
      pricePerMonth: 160,
      pricePerAnnual: 1920,
      itemIncludes: ['something la', 'you will get one la'],
    } as ServicePrice,
  ];

  public TAX_PRICES = [
    {
      name: 'Tax',
      pricePerMonth: 160,
      pricePerAnnual: 1920,
      itemIncludes: ['something la', 'you will get one la'],
    } as ServicePrice,
    {
      name: 'Tax Pro',
      pricePerMonth: 180,
      pricePerAnnual: 2160,
      itemIncludes: ['something la', 'you will get one la'],
    } as ServicePrice,
  ];

  public AUDIT_PRICES = [
    {
      name: 'Audit',
      pricePerMonth: 160,
      pricePerAnnual: 1920,
      itemIncludes: ['something la', 'you will get one la'],
    } as ServicePrice,
  ];

  public SHARED_SERVICES_PRICES = [
    {
      name: 'Shared Services',
      pricePerMonth: 160,
      pricePerAnnual: 1920,
      itemIncludes: ['something la', 'you will get one la'],
    } as ServicePrice,
  ];
}
