import { WhatWeOfferConstants } from '../../../shared/constants/what-we-offer.constants';
import { Component, OnInit } from '@angular/core';
import { WhatWeOffer } from 'src/app/shared/models/what-we-offer/what-we-offer.model';

@Component({
  selector: 'app-home-what-we-offer',
  templateUrl: './home-what-we-offer.component.html',
  styleUrls: ['./home-what-we-offer.component.scss'],
})
export class HomeWhatWeOfferComponent implements OnInit {
  whatWeOfferTypes: WhatWeOffer[];

  constructor(whatWeOfferConstants: WhatWeOfferConstants) {
    this.whatWeOfferTypes = whatWeOfferConstants.WHAT_WE_OFFER_TYPES;
  }

  ngOnInit(): void {}
}
