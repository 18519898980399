import { ContactService } from './../../../core/services/contact/contact.service';
import { JwtService } from './../../../core/services/jwt/jwt.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PendingFile } from 'src/app/shared/models/pending-file/pending-file.model';
import { Enquiry } from 'src/app/shared/models/enquiry/enquiry.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  @Input() isNormal: boolean = false;
  @Input() isSecretarial: boolean = false;
  @Input() isTax: boolean = false;
  @Input() isAudit: boolean = false;
  @Input() isSharedServices: boolean = false;

  // Form
  contactUsForm!: FormGroup;

  // Upload pending files
  pendingFiles: PendingFile[] = [];

  // Boolean
  isUploading: boolean = false;

  // Form Directive
  @ViewChild('formDirective') private formDirective!: NgForm;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private jwtService: JwtService,
    private contactService: ContactService
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    if (this.isNormal) {
      this.contactUsForm = this.formBuilder.group({
        name: new FormControl('', Validators.required),
        phoneNumber: new FormControl('', Validators.required),
        emailAddress: new FormControl('', [
          Validators.required,
          Validators.email,
        ]),
        title: new FormControl('', Validators.required),
        remarks: new FormControl('', Validators.required),
        file: new FormControl(''),
      });
    } else {
      this.contactUsForm = this.formBuilder.group({
        name: new FormControl('', Validators.required),
        phoneNumber: new FormControl('', Validators.required),
        emailAddress: new FormControl('', [
          Validators.required,
          Validators.email,
        ]),
        remarks: new FormControl('', Validators.required),
      });
    }
  }

  uploadFilePressed(event: any): void {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    const pendingFile = {
      folderName: 'file',
      file: files[0],
    } as PendingFile;
    this.pendingFiles.push(pendingFile);
  }

  submitContactForm(): void {
    if (this.contactUsForm.invalid) {
      this.snackBar.open('Please fill in the empty field', '', {
        duration: 3000,
      });
    } else {
      this.isUploading = true;
      // Create object
      let data;
      if (this.isNormal) {
        data = {
          title: this.contactUsForm.get('title')?.value,
          description: this.contactUsForm.get('remarks')?.value,
          name: this.contactUsForm.get('name')?.value,
          email: this.contactUsForm.get('emailAddress')?.value,
          phone: this.contactUsForm.get('phoneNumber')?.value,
        };
      } else {
        data = {
          name: this.contactUsForm.get('name')?.value,
          email: this.contactUsForm.get('emailAddress')?.value,
          phoneNumber: this.contactUsForm.get('phoneNumber')?.value,
          remarks: this.contactUsForm.get('remarks')?.value,
          department: this.getServiceType(),
          hasRead: 0,
        };
      }

      // Disabled contact us form
      this.contactUsForm.disable();
      this.snackBar.open(
        this.pendingFiles.length > 0
          ? 'Creating your message'
          : 'Sending your message',
        '',
        {
          duration: undefined,
        }
      );

      // Change API according to Input()
      let contactService: Observable<Enquiry>;

      if (this.isNormal) {
        contactService = this.contactService.sendContactForm(data);
      } else {
        contactService = this.contactService.sendServiceContactForm(data);
      }

      // Call POST API
      contactService.subscribe({
        next: (createdEnquiry: Enquiry) => {
          if (this.pendingFiles.length > 0) {
            this.uploadFiles(createdEnquiry);
          } else {
            this.isUploading = false;
            this.contactUsForm.enable();
            this.contactUsForm.reset();
            this.formDirective.resetForm();
            this.snackBar.open('Your message has been sent', '', {
              duration: 3000,
            });
          }
        },
        error: (error) => {
          console.log(error);
          this.contactUsForm.enable();
          this.snackBar.open(error, '', {
            duration: 3000,
          });
        },
      });
    }
  }

  getServiceType(): string {
    if (this.isSecretarial) {
      return 'secretarial';
    } else if (this.isTax) {
      return 'tax';
    } else if (this.isAudit) {
      return 'audit';
    } else if (this.isSharedServices) {
      return 'shared_services';
    } else {
      return '';
    }
  }

  uploadFiles(createdEnquiry: Enquiry): void {
    this.snackBar.open('Attaching your file', '', {
      duration: undefined,
    });
    // Let JwtService know that we are accepting only form data at this time
    this.jwtService.saveContentType('multipart/form-data');

    // Create FormData
    const formData: FormData = new FormData();

    // Append file to FormData
    for (let index = 0; index < this.pendingFiles.length; index++) {
      const file: PendingFile = this.pendingFiles[index];
      formData.append(file.folderName, file.file);
    }

    this.contactService
      .uploadContactFormFile(formData, createdEnquiry.id)
      .subscribe({
        next: (_) => {
          this.jwtService.destroyContentType();
          this.isUploading = false;
          this.contactUsForm.reset();
          this.formDirective.resetForm();
          this.contactUsForm.enable();
          this.pendingFiles = [];
          this.snackBar.open('Your message has been sent', '', {
            duration: 3000,
          });
        },
        error: (error) => {
          console.log(error);
          this.jwtService.destroyContentType();
          this.isUploading = false;
          this.contactUsForm.enable();
          this.snackBar.open(error, '', {
            duration: 3000,
          });
        },
      });
  }
}
