import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Event, NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private metaService: Meta,
    private router: Router,
    private gtmService: GoogleTagManagerService
  ) {
    this.addMetaTags();
  }

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      } else {
        this.gtmService.pushTag({
          event: 'page',
          page_name: event.urlAfterRedirects,
        });
      }

      document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0, 0);
    });
  }

  addMetaTags(): void {
    this.metaService.addTags([
      // Add Primary Meta Tags
      {
        name: 'title',
        content: 'Eurogain — Helping businesses grow',
      },
      {
        name: 'description',
        content:
          'At Eurogain, we believe in helping our clients to make their businesses grow. We are passionate about providing professional corporate services in formulating strategy systems and resources so as to have a powerful and positive impact on their businesses.',
      },
      { name: 'robots', content: 'index,follow' },

      // Add OpenGraph/ Facebook Meta Tags
      { property: 'og:type', content: 'website' },
      {
        property: 'og:url',
        content: `${environment.baseUrl}`,
      },
      { property: 'og:title', content: 'Eurogain — Helping businesses grow' },
      {
        property: 'og:description',
        content:
          'At Eurogain, we believe in helping our clients to make their businesses grow. We are passionate about providing professional corporate services in formulating strategy systems and resources so as to have a powerful and positive impact on their businesses.',
      },
      {
        property: 'og:image',
        content: `${environment.baseUrl}/assets/images/eurogain-logo.png`,
      },

      // Add Twitter Meta Tags
      { property: 'twitter:card', content: 'summary_large_image' },
      {
        property: 'twitter:url',
        content: `${environment.baseUrl}`,
      },
      {
        property: 'twitter:title',
        content: 'Eurogain — Helping businesses grow',
      },
      {
        property: 'twitter:description',
        content:
          'At Eurogain, we believe in helping our clients to make their businesses grow. We are passionate about providing professional corporate services in formulating strategy systems and resources so as to have a powerful and positive impact on their businesses.',
      },
      {
        property: 'twitter:image',
        content: `${environment.baseUrl}/assets/images/eurogain-logo.png`,
      },
    ]);
  }
}
