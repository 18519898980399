<!-- Start Desktop -->
<div
  class="container desktop"
  fxLayout="column"
  fxLayoutAlign="center"
  fxLayoutGap="24px"
  fxHide.xs
  fxShow
>
  <!-- Start Title -->
  <h1 class="title">What we <br />offer</h1>
  <!-- End Title -->
  <!-- Start Services -->
  <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="40px grid">
    <div
      fxFlex="calc(33% - 40px)"
      fxFlex.lg="25%"
      fxFlex.xl="20%"
      *ngFor="let whatWeOffer of whatWeOfferTypes"
    >
      <h2>{{ whatWeOffer.title }}</h2>
      <p>{{ whatWeOffer.description }}</p>
    </div>
  </div>
  <!-- End Services -->
</div>
<!-- End Desktop -->
<!-- Start Mobile -->
<div
  class="container"
  ngClass.xs="xs"
  fxLayout="column"
  fxLayoutAlign="center stretch"
  fxLayoutGap="24px"
  fxHide
  fxShow.xs
>
  <!-- Start Title -->
  <h1 class="title">What we <br />offer</h1>
  <!-- End Title -->
  <!-- Start Services -->
  <mat-accordion>
    <mat-expansion-panel
      class="mat-elevation-z0 expansion-panel"
      *ngFor="let whatWeOffer of whatWeOfferTypes; let first = first"
      [ngClass]="{ first: first }"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="panel-title">
          {{ whatWeOffer.title }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="padding-16">
        <p>{{ whatWeOffer.description }}</p>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- End Services -->
</div>
<!-- End Mobile -->
