<app-contact-intro [description]="description"></app-contact-intro>
<div
  fxLayout="column"
  fxLayoutAlign="stretch"
  class="form-container"
  ngClass="desktop"
  ngClass.xs="xs"
>
  <h1 class="title">Drop us a message</h1>
  <p>{{ form_description }}</p>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.xs="start start"
    fxLayoutGap="40px"
    fxLayoutGap.xs="0px"
    class="margin-top-16"
  >
    <div fxLayout="row" fxLayoutAlign="center center" fxFlex fxHide.xs fxShow>
      <img src="assets/images/contact-form.svg" alt="" class="width-80" />
    </div>
    <app-contact-form
      ngClass="width-50"
      ngClass.xs="width-100"
      [isNormal]="true"
    ></app-contact-form>
  </div>
</div>
