import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Enquiry } from 'src/app/shared/models/enquiry/enquiry.model';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private apiService: ApiService) {}

  sendContactForm(data: object = {}): Observable<Enquiry> {
    return this.apiService.post('/api/core/enquiries', data);
  }

  uploadContactFormFile(data: FormData, enquiryID: number): Observable<any> {
    return this.apiService.post(
      `/api/core/enquiries/${enquiryID}/file`,
      data,
      true
    );
  }

  sendServiceContactForm(data: object = {}): Observable<Enquiry> {
    return this.apiService.post('/api/core/services', data);
  }
}
