import { CompanyCreated } from '../models/company-created/company-created.model';
import { WhatWeOffer } from '../models/what-we-offer/what-we-offer.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CompanyCreatedConstants {
  public CREATED_COMPANIES = [
    {
      name: 'SP Setia Bhd Group',
      imgUrl:
        'https://upload.wikimedia.org/wikipedia/commons/9/96/SP-Setia-logo-vector.png',
    } as CompanyCreated,
    {
      name: 'Sime Darby',
      imgUrl:
        'https://seeklogo.com/images/S/sime-darby-logo-DA85D99D2A-seeklogo.com.png',
    } as CompanyCreated,
    {
      name: 'UEM Sunrise',
      imgUrl:
        'https://www.internationalinvestor.com/staging/wp-content/uploads/2017/08/UEMSunrise_LOGOAPPROVED.png',
    } as CompanyCreated,
    {
      name: 'Lulu Hypermarket & Department Store',
      imgUrl:
        'https://www.luluhypermarket.com/_ui/responsive/common/images/lulu_icon.png',
    } as CompanyCreated,
    {
      name: 'Uniqlo',
      imgUrl:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/UNIQLO_logo.svg/1200px-UNIQLO_logo.svg.png',
    } as CompanyCreated,
  ];
}
