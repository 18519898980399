<div
  class="container"
  ngClass="desktop"
  ngClass.sm="sm"
  ngClass.md="md"
  ngClass.xs="xs"
  ngClass.lg="lg"
  ngClass.xl="xl"
  ngClass.gt-md="gt-md"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayout.xs="column-reverse"
  fxLayoutGap.xs="16px"
>
  <!-- Start Title and Description -->
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px" fxFlex>
    <h1 class="title">{{ title }}</h1>
    <p class="text-align-center">
      {{ description }}
    </p>
  </div>
  <!-- End Title and Description -->
  <!-- Start Image -->
  <div fxLayout="column" fxLayoutAlign="center center" fxFlex *ngIf="imageUrl">
    <img class="image" ngClass.xs="xs" [src]="imageUrl" alt="" />
  </div>
  <!-- End Image -->
</div>
