import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArticleCategory } from 'src/app/shared/models/article-category/article-category.model';
import { Article } from 'src/app/shared/models/article/article.model';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ArticlesService {
  constructor(private apiService: ApiService) {}

  // Articles
  getArticles(data: object = {}): Observable<any> {
    return this.apiService.post('/api/core/articlessearch', data);
  }

  getArticleDetails(id: number): Observable<Article> {
    return this.apiService.get(`/api/core/articles/${id}`);
  }

  // Article Category
  getAllArticleCategories(): Observable<Array<ArticleCategory>> {
    return this.apiService.get('/api/core/articles/categories');
  }
}
