<!-- Start Intro Container -->
<!-- <div
  class="intro-container"
  ngClass="desktop"
  ngClass.sm="sm"
  ngClass.md="md"
  ngClass.xs="xs"
  ngClass.lg="lg"
  ngClass.xl="xl"
  ngClass.gt-md="gt-md"
  fxLayout="row"
  fxLayoutAlign="space-between center"
> -->
<!-- Start Title and Description -->
<!-- <div
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="16px"
    class="width-100"
    fxFlex
  >
    <h1 class="title">Articles</h1>
    <p>
      {{ description }}
    </p>
  </div> -->
<!-- End Title and Description -->
<!-- Start Image -->
<!-- <div fxLayout="column" fxLayoutAlign="center center" fxFlex fxHide.xs>
    <img class="image" src="assets/images/articles-intro.svg" alt="" />
  </div> -->
<!-- End Image -->
<!-- </div> -->
<!-- End Intro Container -->
<!-- Start Article List -->
<div
  fxLayout="column"
  fxLayoutGap="40px"
  fxLayoutGap.xs="24px"
  class="articles-container"
  ngClass="desktop"
  ngClass.xs="xs"
>
  <app-category-articles
    *ngFor="let category of categoryOptions | async"
    [category]="category.name.toLowerCase()"
  ></app-category-articles>
</div>
<!-- End Article List -->
