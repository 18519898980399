import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-intro',
  templateUrl: './service-intro.component.html',
  styleUrls: ['./service-intro.component.scss'],
})
export class ServiceIntroComponent implements OnInit {
  @Input() title = '';
  @Input() description = '';
  @Input() imageUrl = '';

  constructor() {}

  ngOnInit(): void {}
}
