import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-intro',
  templateUrl: './home-intro.component.html',
  styleUrls: ['./home-intro.component.scss'],
})
export class HomeIntroComponent implements OnInit {
  description = `
  We serve an exclusive range of coffees and offer a wide assortment of teas 
  to suit every occasion. We believe that coffee is our passion, our passion 
  for quality, our passion for people and our passion for the great coffee 
  experience – and we always will. It's our mission to help people find the 
  joy of coffee, to help people stop worrying, to help people get up and leave 
  their comfort zone and to help people enjoy a cup of tea without the worries, 
  without the stress.
  `;

  constructor() {}

  ngOnInit(): void {}
}
