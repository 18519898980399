<mat-sidenav-container class="height-100">
  <!-- Start Side Navigation -->
  <mat-sidenav
    #sidenav
    #end
    position="start"
    fixedInViewport="fixed"
    ngClass="width-20"
    ngClass.xs="width-85"
    ngClass.sm="width-40"
    ngClass.md="width-30"
  >
    <div fxLayout="row" fxLayoutAlign="center center" class="sidenav-header">
      <img src="assets/images/eurogain-logo.png" alt="" class="width-50" />
    </div>
    <div fxLayout="column" class="sidenav__button">
      <mat-divider></mat-divider>
      <a
        mat-menu-item
        routerLink="/articles"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="sidenav.toggle()"
      >
        <mat-icon>home</mat-icon>
        Home
      </a>
      <a
        mat-menu-item
        routerLink="/articles"
        routerLinkActive="active"
        (click)="sidenav.toggle()"
      >
        <mat-icon>library_books</mat-icon>
        Articles
      </a>
      <a mat-menu-item [matMenuTriggerFor]="servicesMenuMobile">
        <mat-icon>miscellaneous_services</mat-icon>
        Services
      </a>
      <mat-menu #servicesMenuMobile="matMenu">
        <button
          mat-menu-item
          class="uppercase"
          routerLink="/services/secretary"
          (click)="sidenav.toggle()"
        >
          Secretary
        </button>
        <button
          mat-menu-item
          class="uppercase"
          routerLink="/services/tax"
          (click)="sidenav.toggle()"
        >
          Tax
        </button>
        <button
          mat-menu-item
          class="uppercase"
          routerLink="/services/audit"
          (click)="sidenav.toggle()"
        >
          Audit
        </button>
        <button
          mat-menu-item
          class="uppercase"
          routerLink="/services/shared_services"
          (click)="sidenav.toggle()"
        >
          Shared Services
        </button>
      </mat-menu>
      <a
        mat-menu-item
        routerLink="/contact"
        routerLinkActive="active"
        (click)="sidenav.toggle()"
      >
        <mat-icon>call</mat-icon>
        Contact
      </a>
    </div>
  </mat-sidenav>
  <!-- End Side Navigation -->

  <mat-sidenav-content>
    <!-- Start Mobile Toolbar -->
    <mat-toolbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="mat-elevation-z4"
      fxHide
      fxShow.xs
    >
      <button mat-icon-button (click)="sidenav.toggle()" fxHide fxShow.xs>
        <mat-icon>menu</mat-icon>
      </button>
      <a mat-button routerLink="/articles">
        <img src="assets/images/eurogain-logo.png" alt="" class="logo" />
      </a>
      <button mat-button></button>
    </mat-toolbar>
    <!-- End Mobile Toolbar -->
    <!-- Start Desktop Toolbar -->
    <mat-toolbar
      fxLayout="row"
      fxLayoutAlign="center"
      class="mat-elevation-z4"
      fxHide.xs
      fxShow
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="width-100"
        ngClass.xl="max-width-70"
      >
        <a mat-button routerLink="/articles">
          <img src="assets/images/eurogain-logo.png" alt="" class="logo" />
        </a>
        <div fxLayout="row" fxLayoutGap="16px">
          <a mat-button color="primary" class="uppercase" routerLink="/articles"
            >Articles</a
          >
          <button
            mat-button
            color="primary"
            class="uppercase"
            [matMenuTriggerFor]="servicesMenuDesktop"
          >
            Services
          </button>
          <a mat-button color="primary" class="uppercase" routerLink="/contact"
            >Contact</a
          >
          <!-- <a mat-stroked-button color="primary" class="uppercase">Login</a> -->
          <mat-menu #servicesMenuDesktop="matMenu">
            <a mat-menu-item class="uppercase" routerLink="/services/secretary">
              Secretary
            </a>
            <a mat-menu-item class="uppercase" routerLink="/services/tax">
              Tax
            </a>
            <a mat-menu-item class="uppercase" routerLink="/services/audit">
              Audit
            </a>
            <a
              mat-menu-item
              class="uppercase"
              routerLink="/services/shared_services"
            >
              Shared Services
            </a>
          </mat-menu>
        </div>
      </div>
    </mat-toolbar>
    <!-- End Desktop Toolbar -->
    <div
      fxLayout="row"
      fxLayoutAlign="center"
      class="toolbar-height"
      ngClass="desktop"
      ngClass.xs="xs"
    >
      <div
        fxLayout="column"
        fxLayoutAlign="stretch"
        class="width-100"
        ngClass.xl="max-width-70"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- Start Footer -->
    <app-footer></app-footer>
    <!-- End Footer -->
  </mat-sidenav-content>
</mat-sidenav-container>
