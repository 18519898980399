<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
  <!-- Start Category Title -->
  <h2 class="capitalize padding-horizontal-24">{{ category }}</h2>
  <!-- End Category Title -->
  <!-- Start Loading -->
  <div fxLayout="row" class="width-100" *ngIf="isLoading">
    <div
      class="border border-color border-radius-8 padding-24 shimmer"
      fxFlex="25%"
      fxFlex.xs="calc(100%)"
      fxFlex.sm="50%"
      fxFlex.md="50%"
      fxFlex.lg="33%"
      fxFlex.xl="20%"
    >
      <div class="article-image margin-bottom-16"></div>
      <h2>Loading</h2>
      <p>Loading</p>
    </div>
  </div>
  <!-- End Loading -->
  <p
    class="not-available italic padding-horizontal-24"
    *ngIf="!articles.length && !isLoading"
  >
    It seems like we have not published anything for {{ category }} yet
  </p>
  <!-- Start Articles -->
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    fxLayoutGap="40px grid"
    fxLayout.xs="column"
    fxLayoutAlign="stretch"
    fxLayoutGap.xs="16px"
    *ngIf="articles.length > 0 && !isLoading"
    class="width-100"
  >
    <!-- Start Article Item -->
    <div
      fxFlex="25%"
      fxFlex.xs="calc(100%)"
      fxFlex.sm="50%"
      fxFlex.md="50%"
      fxFlex.lg="33%"
      fxFlex.xl="20%"
      fxFlex="grow"
      *ngFor="let article of articles"
    >
      <a
        mat-stroked-button
        class="width-100"
        [routerLink]="article.id.toString()"
      >
        <!-- Start Article Image -->
        <div
          class="article-image margin-bottom-16"
          [style.backgroundImage]="
            article.fileUrl ? 'url(' + getImageUrl(article) + ')' : null
          "
          [style.backgroundSize]="article.fileUrl ? 'contain' : 'cover'"
        ></div>
        <!-- End Article Image -->
        <!-- Start Article Title & Short Description -->
        <h2 class="ellipsis title">{{ article.mainTitle }}</h2>
        <p class="ellipsis">{{ article.shortDescription }}</p>
        <!-- End Article Title & Short Description -->
      </a>
    </div>
    <!-- End Article Item -->
  </div>
  <!-- End Articles -->
</div>
