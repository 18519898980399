import { QuestionAnswer } from './../../../shared/models/question-answer/question-answer.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-faq',
  templateUrl: './service-faq.component.html',
  styleUrls: ['./service-faq.component.scss'],
})
export class ServiceFaqComponent implements OnInit {
  @Input() questionsAnswers!: QuestionAnswer[];

  constructor() {}

  ngOnInit(): void {}
}
